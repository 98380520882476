import functions from '../functions';
import { list } from './list';

$(() => {
    listTravel.init();
})

const listTravel = {
    defaults: {
        ajax: true,
        _page: 1,
        perPage: 20,
        language: functions.getLang(),
        destination: '',
        countryId: '',
        objectGroupId: 6,
        hasArrival: 2,
    },
    params: {},
    filterParams: {
        tagCategoryId: null,
        transportationType: null,
        objectTypeId: null,
        sortBy: null, 
    },
    init() {

        this.$form = $('.search-form.search-travel');
        this.form = this.$form[0];

        this.params.objectGroupId = this.$form.last().attr('data-objectgroupid') ? this.$form.last().attr('data-objectgroupid') : null;

        if(! this.form || this.params.objectGroupId != this.defaults.objectGroupId) {
            return;
        }

        this.$listItems = $('.list-items');

        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;
        this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectTypeId = this.$form.last().attr('data-objecttypeid');
        this.defaults.tagCategoryId = this.$form.last().attr('data-tagcategoryid');
        this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');
        this.defaults.destination = this.$form.last().attr('data-destination') ? this.$form.last().attr('data-destination') : this.defaults.countryId;
        this.defaults.perPage = this.$form.last().attr('data-perpage') ? this.$form.last().attr('data-perpage') : this.defaults.perPage;

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0]; 

        if(! this.isHome) {

            this.getParams();
            
            this.params.tagCategoryId = this.params.tagCategoryId ? this.params.tagCategoryId : this.$form.last().attr('data-tagcategoryid');

            functions.setFormData(this.form, this.params);
            if(this.filter) {
                functions.setFormData(this.filter, this.params);
            }
            this.params.guests = $('[name=guests]').val();
            this.find(this.params);
        } else {
            functions.setFormData(this.form, this.defaults);
        }

        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.objectTypeId = $target.attr('data-objecttypeid');
                this.params.tagCategoryId = $target.attr('data-tagcategoryid');
                this.params.countryId = this.params.destination = $target.attr('data-countryid');
                this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
            }

            this.params = $.extend({}, this.defaults, functions.getFormData($target));
            this.params = this.filter ? $.extend(this.params, this.filterParams, functions.getFormData(this.$filter)) : this.params;
            this.params._page = this.defaults._page;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.objectTypeId = $target.attr('data-objecttypeid');
                this.params.tagCategoryId = $target.attr('data-tagcategoryid');
                
                this.params = functions.cleanParams(this.params, this.defaults);
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true, this.defaults);
            }

        });

        this.$filter.on('change', e => {
            const $target = $(e.currentTarget);
            e.preventDefault();

            if ( this.stopChangeEvents ) {
                return;
            }
            
            this.params = $.extend(this.params, this.filterParams, functions.getFormData($target));
            this.params._page = this.defaults._page;
            functions.setUrlData(this.params, true, false, this.defaults);
        });

        this.$filter.on('submit', e => {
            e.preventDefault();
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;

            if ( this.filter ) {               
                functions.setFormData(this.filter, this.params);
            }

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });
    },
    getParams() {
        list.defaults = this.defaults;
        this.params = list.params = list.getParams(this.params, this.defaults, this.filterParams);
    },
    find(params) {
        list.find(params).then((response) => {
            list.findDone(response, params, this.defaults);
            // LOZAD
            window.observer.observe();
            this.initComponents();
        });
    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true, false, this.defaults);
    },
    initComponents() {
        this.$sortBy = $('[name="sortBy"]');
        this.sortBy = this.$sortBy[0] ? this.$sortBy[0] : null;

        if(this.sortBy && this.params.sortBy) {
            this.$sortBy.val(this.params.sortBy);
        }

        this.$sortBy.on('change', e => {
            this.params = $.extend(this.params, this.getParams(), functions.getFormData(this.$sortBy));
            functions.setUrlData(this.params, true, true, this.defaults);
        });

        // register paginate        
        $('[data-page]').on('click', e => {
            e.preventDefault();
            this.paginate(+$(e.currentTarget).attr('data-page'));
        });
    }
}