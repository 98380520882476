import functions from '../functions';
import { list } from './list';
import Slider from "../components/Slider";

$(() => {
    listTravel.init();
})

const listTravel = {
    defaults: {
        _page: 1,
        perPage: 100,
        language: functions.getLang(),
        objectGroupId: 10
    },
    params: {},
    init() {

        this.$form = $('.search-form.search-ski-area');
        this.form = this.$form[0];

        this.params.objectGroupId = this.$form.last().attr('data-objectgroupid') ? this.$form.last().attr('data-objectgroupid') : null;

        if(! this.form || this.params.objectGroupId != this.defaults.objectGroupId) {
            return;
        }

        this.$listItems = $('.list-items');

        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;
        this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectTypeId = this.$form.last().attr('data-objecttypeid');
        this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');
        this.defaults.destination = this.$form.last().attr('data-destination') ? this.$form.last().attr('data-destination') : this.defaults.countryId;
        //this.defaults.perPage = this.$form.last().attr('data-perpage') ? this.$form.last().attr('data-perpage') : this.defaults.perPage;


        this.getParams();
        this.find(this.params);
        functions.setFormData(this.form, this.params);

        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.params = $.extend({}, this.defaults, functions.getFormData($target));
            this.params._page = this.defaults._page;

            functions.setUrlData(this.params, true, true, this.defaults);

        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;

            //functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });
    },
    getParams() {
        list.defaults = this.defaults;
        this.params = list.params = list.getParams(this.params, this.defaults, this.filterParams);
    },
    find(params) {
        list.find(params).then((response) => {  
            list.findDone(response, params, this.defaults);
            // LOZAD
            window.observer.observe();
            this.initComponents();
        });
    },
    initComponents() {
        let catalogueSlider = [];
        $('[id^=catalogue-slider]').each((i, elem) => {
            const length = $(elem).find('.keen-slider__slide').length;
            catalogueSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: false,
                arrows: ! functions.isMobile(),
                pager: true,
                slides: {
                    //origin: "center",
                    perView: 3.2,
                    spacing: 8,
                },
                breakpoints: {
                '(max-width: 768px)': {
                    arrows: false,
                    slides: {
                        perView: 1.2,
                        spacing: 15,
                    },
                },
            }
            });
        });
    }
}